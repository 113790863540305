var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle || "分配权限",
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c(
        "div",
        { staticClass: "corp-detail-drawer-component" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                _vm._l(_vm.permissionList, function(group) {
                  return _c(
                    "a-tab-pane",
                    {
                      key: group.id,
                      attrs: { "force-render": "", tab: group.name }
                    },
                    [
                      _vm.IsPlatformPermission
                        ? _c(
                            "a-descriptions",
                            {
                              staticClass: "tab-margin-16",
                              attrs: { bordered: "", column: 1 }
                            },
                            [
                              _vm._l(group.children, function(item) {
                                return [
                                  _c(
                                    "a-descriptions-item",
                                    { key: item.id },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label"
                                        },
                                        [
                                          _c(
                                            "a-checkbox",
                                            {
                                              attrs: {
                                                indeterminate:
                                                  item.Indeterminate
                                              },
                                              model: {
                                                value: item.AllSelect,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "AllSelect",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.AllSelect"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(item.children, function(child) {
                                        return [
                                          _c(
                                            "a-checkbox",
                                            {
                                              key: child.id,
                                              model: {
                                                value: child.select,
                                                callback: function($$v) {
                                                  _vm.$set(child, "select", $$v)
                                                },
                                                expression: "child.select"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(child.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : _c(
                            "div",
                            [
                              group.id === "LOCATION"
                                ? [
                                    _c("a-tree", {
                                      staticClass: "location-tree",
                                      attrs: {
                                        "default-expand-all": true,
                                        checkable: false,
                                        "check-strictly": false,
                                        multiple: false,
                                        selectable: false,
                                        "tree-data-simple-mode": "",
                                        "tree-node-filter-prop": "title",
                                        "tree-data": group.children
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "custom",
                                            fn: function(item) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.treeItemOnClick(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a-checkbox",
                                                      {
                                                        model: {
                                                          value: item.check,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "check",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.check"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ]
                                : _vm._l(group.children, function(menu) {
                                    return _c(
                                      "div",
                                      {
                                        key: menu.id,
                                        staticClass: "menu-item-wrapper"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "menu-item-header" },
                                          [
                                            _c(
                                              "a-checkbox",
                                              {
                                                attrs: {
                                                  indeterminate:
                                                    menu.Indeterminate
                                                },
                                                model: {
                                                  value: menu.AllSelect,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      menu,
                                                      "AllSelect",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "menu.AllSelect"
                                                }
                                              },
                                              [_vm._v(_vm._s(menu.name))]
                                            )
                                          ],
                                          1
                                        ),
                                        menu.HasChildren
                                          ? _c(
                                              "a-descriptions",
                                              {
                                                attrs: {
                                                  bordered: "",
                                                  column: 1
                                                }
                                              },
                                              [
                                                _vm._l(menu.children, function(
                                                  page
                                                ) {
                                                  return [
                                                    _c(
                                                      "a-descriptions-item",
                                                      { key: page.id },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              slot: "label"
                                                            },
                                                            slot: "label"
                                                          },
                                                          [
                                                            _c(
                                                              "a-checkbox",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    page.Indeterminate
                                                                },
                                                                model: {
                                                                  value:
                                                                    page.AllSelect,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      page,
                                                                      "AllSelect",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "page.AllSelect"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      page.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._l(
                                                          page.children,
                                                          function(func) {
                                                            return [
                                                              _c(
                                                                "a-checkbox",
                                                                {
                                                                  key: func.id,
                                                                  model: {
                                                                    value:
                                                                      func.select,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        func,
                                                                        "select",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "func.select"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        func.name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                })
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  })
                            ],
                            2
                          )
                    ],
                    1
                  )
                }),
                1
              ),
              _c("br"),
              _c(
                "jtl-button",
                {
                  staticClass: "save-button",
                  attrs: { type: "primary", "click-prop": _vm.save.bind(this) }
                },
                [_vm._v("保存权限")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }