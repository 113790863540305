
import { RoleEntityModel, RoleQueryModel } from '@/entity-model/role-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';
import PermissionService from './permission';
import CorpService from './corp';
import { PermissionType } from '@/model/enum';
import { PermissionEntityModel } from '@/entity-model/permission-entity';
import SpaceService from '@/service/space-v2';
import { SpaceTreeEntityModel } from '@/entity-model/space-entity';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/role`;
export const LOCATION_PERMISSION_ID = 'LOCATION';
class RoleService implements ICRUDQ<RoleEntityModel, RoleQueryModel> {
    async create(model: RoleEntityModel):Promise<RoleEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<RoleEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new RoleEntityModel().toModel(res);
    }

    async update(model: RoleEntityModel):Promise<RoleEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: RoleEntityModel):Promise<RoleEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: RoleQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/getRoles`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await get(url, params);
        return _.map(res, item => item = new RoleEntityModel().toModel(item));
    }

    async getRolePermissions(id: string) {
        const url = `${URL_PATH}/permissions/${id}`;
        const res = await get(url);
        return res;
    }

    async getRoleLocationPermissions(id: string) {
        const url = `${URL_PATH}/entities/relation/${id}/${LOCATION_PERMISSION_ID}`;
        const res = await get(url);
        return res;
    }

    async getPermissions(modelId: string, permissionType: PermissionType):Promise<Array<PermissionEntityModel | SpaceTreeEntityModel>> {
        let permissions: Array<PermissionEntityModel | SpaceTreeEntityModel> = null;
        let selectedPermissions: Array<string> = null;
        switch (permissionType) {
            case PermissionType.PLATFORM:
                permissions = await PermissionService.getAllPermission();
                selectedPermissions = await CorpService.getCorpPermission(modelId);
                break;
            case PermissionType.CORP:
            case PermissionType.PROJECT:
            {
                permissions = await CorpService.getCorpPermissionByType(permissionType);
                selectedPermissions = await this.getRolePermissions(modelId);
                if (permissionType === PermissionType.PROJECT) {
                    const locationPermissions = await SpaceService.getLocationTree();
                    if (!permissions) {
                        permissions = [];
                    }
                    const groupPermission = new PermissionEntityModel();
                    groupPermission.id = LOCATION_PERMISSION_ID;
                    groupPermission.name = '空间权限';
                    groupPermission.children = locationPermissions;
                    groupPermission.level = 1;
                    permissions.push(groupPermission);
                    const selectedLocationPermissions = await this.getRoleLocationPermissions(modelId);
                    selectedPermissions = _.union(selectedPermissions, selectedLocationPermissions);
                }
                break;
            }
        }
        if (selectedPermissions && selectedPermissions.length > 0) {
            _.forEach(permissions, item => {
                if (item.setSelect) {
                    item.setSelect(selectedPermissions);
                }
            });
        }
        return permissions;
    }
    async saveRolePrivs(modelId: string, permissions: Array<PermissionEntityModel>, permissionType: PermissionType):Promise<any> {
        let res = null;
        switch (permissionType) {
            case PermissionType.PLATFORM:
                res = await CorpService.saveCorpPermission(modelId, permissions);
                break;
            case PermissionType.CORP:
                await this.savePermission(modelId, _.filter(permissions, item => item.id !== LOCATION_PERMISSION_ID));
                break;
            case PermissionType.PROJECT:
                await this.savePermission(modelId, _.filter(permissions, item => item.id !== LOCATION_PERMISSION_ID));
                await this.saveLocationPermission(modelId, _.get(_.find(permissions, item => item.id === LOCATION_PERMISSION_ID), 'children') as any);
                break;
        }
        return res;
    }
    async savePermission(id: string, permissionList: Array<PermissionEntityModel>):Promise<any> {
        const url = `${URL_PATH}/permission/${id}`;
        const params = _.map(_.filter(_.flatMapDepth(permissionList, 'children', 1), item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService());
        const res = await post(url, params);
        return res;
    }
    async saveLocationPermission(id: string, permissionList: Array<SpaceTreeEntityModel>):Promise<any> {
        const url = `${URL_PATH}/entities/relation/${id}/${LOCATION_PERMISSION_ID}`;
        const params = _.flatMapDeep(_.map(_.filter(permissionList, (item: SpaceTreeEntityModel) => item.check), (item: SpaceTreeEntityModel) => item.toRoleService()));
        const res = await post(url, params);
        return res;
    }
}

export default new RoleService();
