



















































































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import RoleService, { LOCATION_PERMISSION_ID } from '@/service/role-v2';
import { PermissionType } from '@/model/enum';
import { PermissionEntityModel } from '@/entity-model/permission-entity';
import { UserStoreModule } from '@/store/modules/user';
import SpaceService from '@/service/space-v2';
import { SpaceTreeEntityModel } from '@/entity-model/space-entity';

@Component
export default class CorpDetailComponent extends DrawerComponent {
    permissionList: Array<PermissionEntityModel | SpaceTreeEntityModel> = null;
    modelId: string;
    permissionType: PermissionType;
    loading: boolean = true;

    get IsPlatformPermission() {
        return this.permissionType === PermissionType.PLATFORM;
    }

    drawerRoleOpen(id: string, type: PermissionType) {
        this.modelId = id;
        this.permissionType = type;
        this.drawerVisible = true;
        this.init();
    }

    init() {
        this.loading = true;
        RoleService.getPermissions(this.modelId, this.permissionType)
            .then(res => {
                this.permissionList = res;
                // if (!this.IsPlatformPermission) {
                //     this.initLocation();
                // }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    initLocation() {
        SpaceService.getLocationTree().then(res => {
            const locationPermission: any = {
                id: LOCATION_PERMISSION_ID,
                name: '空间权限',
                children: res
            };
            this.permissionList.push(locationPermission);
        });
    }

    treeItemOnClick(treeItem: {dataRef: SpaceTreeEntityModel}) {
        const checkValue = !treeItem.dataRef.check;
        this.checkTree(treeItem.dataRef, checkValue);
        if (checkValue) {
            this.checkParentTree(treeItem.dataRef.parent, true);
        }
    }

    private checkParentTree(treeParentItem, check: boolean) {
        if (!treeParentItem) {
            return;
        }
        treeParentItem.check = check;
        this.checkParentTree(treeParentItem.parent, check);
    }

    private checkTree(treeItem, check: boolean) {
        treeItem.check = check;
        _.forEach(treeItem.children, child => {
            this.checkTree(child, check);
        });
    }

    save() {
        return RoleService.saveRolePrivs(this.modelId, this.permissionList as any, this.permissionType).then(res => {
            if (!UserStoreModule.IsSuperAdmin) {
                this.$eventHub.$emit('MENU_RELOAD');
            }
            this.showMessageSuccess('保存权限成功');
        }).catch(err => {
            throw err;
        });
    }
}
